import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useSelector } from 'react-redux';
import { StoreStatus } from '@/localization/localizationRail/StoreTile/constants';
import { Occasion, OccasionString } from '@/localization/constants';
import transformPromiseTime from '@/localization/common/transformPromiseTime';
import LocationIcon from './icons/LocationIcon';
import borders from '@/common/borders';
import boxShadows from '@/common/boxShadows';
import useStyles from './styles';
import useOpenLocalizationWithWarningModal from './useOpenLocalizationWithWarningModal';
import { onHeaderChangeLocationClick } from '@/dataAnalytics/dataAnalyticsHelper';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { customerSelectors } from '@/clientCore/redux/selectors/customerSelectors';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import { OccasionDetail } from '@pizza-hut-us-development/client-core';
import { formatCCOpenTime } from '@/clientCore/helper/timeHelpers';

interface LocalizationGridProps {
  isMobile?: boolean;
}

interface LocalizationDetailsProps {
  className: string;
  onClick?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  tabIndex?: number;
}

const LocalizationGrid = ({
  isMobile
}: LocalizationGridProps) => {
  const classes = useStyles();
  const openLocalizationWithWarningModal = useOpenLocalizationWithWarningModal({});

  const searchAddress = useSelector(customerSelectors.searchAddress);
  const storeDetails = useSelector(orderSelectors.store);
  const cart = useSelector(orderSelectors.cart);
  const { occasions } = storeDetails ?? {};
  const { occasionId } = cart ?? {};
  const currentOccasion = occasions?.find((occasion: OccasionDetail) => occasionId === occasion.occasionId);

  const analytics = useAnalytics();

  useEffect(() => {
    if (isMobile) {
      const headerContainer = document.querySelector('header[data-testid="header-container"]');
      (headerContainer as any).style.boxShadow = 'none';
      (headerContainer as any).style.borderBottom = borders.gray400Border;

      window.addEventListener('scroll', () => {
        if (window.scrollY > 36) {
          (headerContainer as any).style.boxShadow = boxShadows.headerShadow;
          (headerContainer as any).style.border = 'none';
        } else {
          (headerContainer as any).style.boxShadow = 'none';
          (headerContainer as any).style.borderBottom = borders.gray400Border;
        }
      });
    }
  }, [isMobile]);

  const determinePromiseTimeText = () => {
    const { serviceTime } = currentOccasion ?? {};
    const { status: storeStatus, availability } = storeDetails ?? {};

    const openTime = formatCCOpenTime(availability?.find((slot) => slot.occasionId === currentOccasion?.occasionId)?.intervalStartTime);

    const occasionId = currentOccasion?.occasionId[0].toUpperCase() ?? '';
    
    const promiseTimeText = `${OccasionString[occasionId as keyof typeof OccasionString]} ${transformPromiseTime(occasionId, serviceTime)}`.trim();

    switch (storeStatus) {
      case StoreStatus.CARRYOUT_ONLY: {
        if (occasionId === Occasion.DELIVERY) return 'Store Closed';

        return promiseTimeText;
      }

      case StoreStatus.DELIVERY_ONLY: {
        if (occasionId === Occasion.CARRYOUT) return 'Store Closed';

        return promiseTimeText;
      }

      case StoreStatus.DELIVERY_AND_CARRYOUT:
        return promiseTimeText;
      case StoreStatus.TEMP_CLOSED_WITH_REASON:
      case StoreStatus.PERM_OFFLINE:
        return 'Store Closed';
      default: {
        const getOpenTimeText = openTime ? `Open ${openTime}` : '';
        return `${OccasionString[occasionId as keyof typeof OccasionString] ?? ''} ${getOpenTimeText}`.trim();
      }
    }
  };

  const handleOnKeyDown = (event: any) => {
    if (event.key === 'Enter') openLocalizationWithWarningModal();
  };

  const getLocalizedAddress = () => (
    currentOccasion?.occasionId === Occasion.DELIVERY
      ? searchAddress
      : (storeDetails?.address1 || searchAddress)
  );

  const sendAnalytics = () => {
    analytics.push(() => onHeaderChangeLocationClick());
  }

  const LocalizationDetails = ({
    className, ...rest
  }: LocalizationDetailsProps) => (
    <Grid
      className={className}
      data-testid="localization-grid"
      data-analytics-category="global_header"
      data-analytics-action="Change Location"
      onClick={sendAnalytics}
      {...rest}
    >
      <Grid className={classes.localizationPromiseTime} data-testid="header-promise_time-text">
        {determinePromiseTimeText()}
      </Grid>
      <Grid container wrap="nowrap">
        <Grid className={classes.localizationAddress} data-testid="header-address-text">
          {getLocalizedAddress()}
        </Grid>
        <ArrowForwardIosIcon fontSize="small" className={classes.caretIcon} />
      </Grid>
    </Grid>
  );

  const LocalizationGridDesktop = () => (
    <Grid item container className={classes.localization}>
      <Grid
        className={classes.desktopWrapper}
        onClick={openLocalizationWithWarningModal}
        onKeyDown={handleOnKeyDown}
        tabIndex={0}
        aria-haspopup
        aria-label={`Selected store location: ${getLocalizedAddress()}`}
      >
        {!isMobile && <LocationIcon data-testid="header-location-icon" className={classes.locationIcon} />}
        <LocalizationDetails
          className={classes.localizationDetails}
        />
      </Grid>
    </Grid>
  );

  const LocalizationGridMobile = () => (
    <Grid
      className={classes.mobileWrapper}
      aria-haspopup
      aria-label={`Selected store location: ${getLocalizedAddress()}`}
    >
      <LocalizationDetails
        className={classes.localizationDetailsMobile}
        onClick={openLocalizationWithWarningModal}
        onKeyDown={handleOnKeyDown}
        tabIndex={0}
      />
    </Grid>
  );

  return isMobile
    ? <LocalizationGridMobile />
    : <LocalizationGridDesktop />;
};

export default LocalizationGrid;
