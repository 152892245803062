import { AppBar, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CoreConfigActions } from '@pizza-hut-us-development/client-core';
import getConfig from 'next/config';
import { useDecision } from '@optimizely/react-sdk';
import AnchorLink from '../common/AnchorLink';
import { HiddenOnDevice } from '../common/ResponsiveContext';
import { useVersionQuery } from '@/graphql/hooks/useVersionQuery';
import {
  CC_GQL_PARTIAL_HEADER_LOCALIZED,
  HEADER_NATIONAL
} from '../graphql/queries';
import LocalizationGrid from './LocalizationGrid';
import Logo from './Logo';
import Menu from './menu';
import Profile from './profile';
import useStyles from './styles';
import usePageLoadingBar from '../hooks/usePageLoadingBar';
import useEnvQueryVariables from '../graphql/hooks/variables/useEnvQueryVariables';
import { localizationSelectors } from '@/localization/localizationSelectors';
import OverridesAlert from '@/configuration/components/OverridesAlert';
import { useCCGetCategoriesQuery } from '@/clientCore/temporaryTransformationalHooks/useCCGetCategoriesQuery';
import CategoryFragment from '@/graphql/types/fragment/Category';
import {
  CCOccasionChoicesTransformer,
  CCOccasionPicker
} from '@/clientCore/temporaryTransformationalHooks/types';
import useTrackedDecision from '@/dataAnalytics/hooks/useTrackedDecision';
import {
  closeConfirmLocationRail,
  openConfirmLocationRail,
  selectors
} from '@/rail/slices/Rail.slice';
import { RailType } from '@/rail/slices/Rail.slice.types';
import ConfirmLocationRail from '@/rail/railContent/confirmLocation/ConfirmLocationRail';
import { openLocalizationRail } from '@/localization/actions';
import { Paths } from '@/router/nextJsRouter';
import CCCartNavBarButton from '@/clientCore/cart/components/CartRail/components/CartNavBarButton';
import { userDomainSelectors } from './userDomainSelectors';
import { callOptimizelyStoreApi } from '../../optimizely/utils/apiClient';
import { getOrInitializeOptimizely } from '../../optimizely/optimizely';
import { useTemporaryCCContext } from '@/clientCore/temporaryTransformationalHooks/temporaryCCContext';
import useWindowFunctions from './useWindowFunctions';
import { NATIONAL_STORE_ID_FULL } from '@/localization/constants';
import { CartManager } from '@/clientCore/cart/components/CartManager';
import useCaliforniaDeliveryFeeModal from '@/homepage/Homepage/hooks/useCaliforniaDeliveryFeeModal';
import LocalizationBar from './LocalizationBar';
import { HeaderSkeleton } from './Header.skeleton';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import LocalizationSkeleton from './LocalizationSkeleton';

declare global {
  interface Window {
    Rokt: any;
  }
}

const Header = (): JSX.Element => {
  const { publicRuntimeConfig } = getConfig();
  const classes = useStyles();
  const dispatch = useDispatch();
  const envQueryVariables = useEnvQueryVariables();
  const legacyStoreDetails = useSelector(localizationSelectors.storeDetails);
  const storeDetails = useSelector(orderSelectors.store);
  const isLoggedIn = useSelector(userDomainSelectors.isAuthenticated);
  const cart = useSelector(orderSelectors.cart);
  const isLegacyLocalized = useSelector(localizationSelectors.isLocalized);
  const isLocalized = !!storeDetails?.storeNumber && !!cart?.occasionId && !!cart?.cartId;
  const isAuthenticatedUser = useSelector(userDomainSelectors.isAuthenticatedUser);
  const occasion: OccasionChoice = useSelector(localizationSelectors.occasion);
  const [{ enabled: headerSkeletonEnabled }] = useDecision('fr-web-3610-header_skeleton_loader');
  const [railDecision] = useTrackedDecision('exp-abt-57-landing-rail');
  const [{ enabled: persistentLocalizationEnabled }] = useDecision('dtg-704-persistent-localization-ctas-on-national-pages');
  const [{ enabled: newAuthenticationStateEnabled }] = useDecision('fr-web-3706-accurate_auth_state');
  const confirmLocationRailIsShown = useSelector(
    selectors.isConfirmLocationShown
  );
  const { setIsOptimizelyApiLoading, setIsOptimizelyApiLoaded } = useTemporaryCCContext();

  useWindowFunctions();
  useCaliforniaDeliveryFeeModal();

  // We dispatch this from the header, since it touches every page
  // this lets us make this change in one spot, and have it support all flows
  const optimizely = getOrInitializeOptimizely();

  useEffect(() => {
    const isValidStore = legacyStoreDetails?.storeNumber;

    if (!isValidStore) {
      dispatch(CoreConfigActions.setIsYumEcomm(false));
      return;
    }

    getIsYumStore(legacyStoreDetails?.storeNumber);

    async function getIsYumStore(storeNumber: string) {
      setIsOptimizelyApiLoading(true);
      // This is where we make our Optimizely TAPI call used for all TAPI related decisions
      // No catch here, because the error is handled in the API fn
      callOptimizelyStoreApi(
        storeNumber,
        publicRuntimeConfig?.OPTIMIZELY_WEB_PROJECT
      )
        .then(() => {
          const isStoreYumEnabled = optimizely?.isFeatureEnabled('ops-web-2029-cc_yum_enabled_store');
          const isStoreYumAuthEnabled = optimizely?.isFeatureEnabled('ops-web-3578-yum_auth_enabled_store');
          const isUserAuthenticated = newAuthenticationStateEnabled ? isAuthenticatedUser : isLoggedIn;
          const shouldBeYumEcomm = !!(isStoreYumEnabled && !isUserAuthenticated) || !!(isStoreYumEnabled && isStoreYumAuthEnabled);
          dispatch(CoreConfigActions.setIsYumEcomm(shouldBeYumEcomm));
        })
        .finally(() => {
          setIsOptimizelyApiLoading(false);
          setIsOptimizelyApiLoaded(true);
        });
    }
  }, [
    legacyStoreDetails?.storeNumber,
    newAuthenticationStateEnabled,
    isAuthenticatedUser,
    isLoggedIn,
    dispatch,
    optimizely,
    publicRuntimeConfig?.OPTIMIZELY_WEB_PROJECT,
    setIsOptimizelyApiLoading,
    setIsOptimizelyApiLoaded
  ]);

  useEffect(() => {
    if (!railDecision?.enabled) return;

    let showConfirmLocationRail = false;
    let isUserLandedOrRouted = false;

    if (
      'getEntriesByType' in window?.performance
      && typeof window?.performance?.getEntriesByType === 'function'
    ) {
      const windowNavigation = window?.performance?.getEntriesByType(
        'navigation'
      ) as PerformanceNavigationTiming[];
      isUserLandedOrRouted = windowNavigation
        .map((nav: PerformanceNavigationTiming) => nav.type)
        .includes('navigate');
    }

    if (isUserLandedOrRouted) {
      showConfirmLocationRail = !confirmLocationRailIsShown;
    }

    if (showConfirmLocationRail) {
      setTimeout(() => {
        if (isLegacyLocalized) {
          dispatch(openConfirmLocationRail(RailType.CONFIRM_LOCATION));
        } else {
          dispatch(openLocalizationRail({ routeTo: Paths.DEALSW2 }));
          dispatch(closeConfirmLocationRail(RailType.CONFIRM_LOCATION));
        }
      }, 1000);
    }
  }, [dispatch, railDecision, confirmLocationRailIsShown, isLegacyLocalized]);

  const formattedOccasion = CCOccasionPicker[occasion as keyof CCOccasionChoicesTransformer];

  const {
    data, loading, error
  } = useVersionQuery({
    queryFn: isLegacyLocalized ? CC_GQL_PARTIAL_HEADER_LOCALIZED : HEADER_NATIONAL,
    storeSpecific: true,
    itemId: '',
    options: envQueryVariables,
    nationalOverrides: true,
    productDomain: undefined,
    parserOptions: { customerIsNational: !isLegacyLocalized }
  });

  const { data: ccData, loading: ccLoading } = useCCGetCategoriesQuery({
    occasion: formattedOccasion,
    storeNumber: legacyStoreDetails?.storeNumber ?? NATIONAL_STORE_ID_FULL
  });

  const finishedLoadingButNoData = !loading && !data?.header && !ccLoading && !ccData;
  usePageLoadingBar(loading && ccLoading);

  if (error || finishedLoadingButNoData || (!headerSkeletonEnabled && !data?.header)) {
    return <></>;
  }

  if (headerSkeletonEnabled && (loading || ccLoading)) {
    return <HeaderSkeleton />;
  }

  if (loading || !data || !ccData) {
    return <></>;
  }

  const {
    logoImage,
    mainNavMenuLinkText,
    mainNavDealsLink,
    profileIcons,
    legacyUserConverter,
    accountSublinks,
    cartImage,
    cartLinkTitle
  } = data.header;

  const menuCategories: LinkContent[] | undefined = (ccData as CategoryFragment[])?.map(
    (category) => category.link
  );

  const MenuSection = (): JSX.Element => (
    <Grid item className={classes.menuItems} role="navigation">
      <Grid className={classes.menuItem}>
        <AnchorLink
          {...mainNavDealsLink}
          tabIndex={0}
          testId="nav-deals"
          className={classes.mainNavLink}
          dataAnalyticsCategory="global_header"
          dataAnalyticsAction={mainNavDealsLink?.linkDisplayText}
        />
      </Grid>
      <Menu
        menuLinkText={mainNavMenuLinkText}
        menuCategories={menuCategories ?? []}
        classes={classes}
      />
    </Grid>
  );

  // Render our skeleton loader when legacy is localized, but CC is not yet localized
  const LocalizationBackup = (isMobile: boolean) => (isLegacyLocalized ? <LocalizationSkeleton isMobile={isMobile} /> : null);

  const LocalizationDesktop = (): JSX.Element => (
    <HiddenOnDevice mdDown implementation="css">
      {isLocalized ? <LocalizationGrid /> : LocalizationBackup(false)}
    </HiddenOnDevice>
  );

  const LocalizationMobile = (): JSX.Element => (
    <HiddenOnDevice lgUp implementation="css">
      {isLocalized ? <LocalizationGrid isMobile /> : LocalizationBackup(true)}
    </HiddenOnDevice>
  );

  return (
    <>
      <OverridesAlert />
      <AppBar
        color="inherit"
        classes={{ root: classes.root }}
        data-testid="header-container"
        role="banner"
        elevation={1}
      >
        <Grid container className={classes.containerGrid}>
          <Logo logoImage={logoImage} />
          <MenuSection />
          <LocalizationDesktop />
          <Profile
            profileLinks={accountSublinks}
            upgradeToRewards={legacyUserConverter}
            profileIcons={profileIcons}
          />
          <CartManager />
          <CCCartNavBarButton cartImage={cartImage} cartLinkTitle={cartLinkTitle} />
        </Grid>
        { (persistentLocalizationEnabled && !isLocalized && !isLegacyLocalized) && <LocalizationBar />}
      </AppBar>
      <LocalizationMobile />
      <ConfirmLocationRail />
    </>
  );
};

export default Header;
